<template>
  <ion-app>
    <div v-if="isOffline" class="offline-status">
      <ODNIconOffline />
    </div>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  alertController,
  getPlatforms,
  toastController,
} from '@ionic/vue';
import { Network } from '@capacitor/network';

import { BarcodeReader } from 'dynamsoft-javascript-barcode';

import { mapState, mapActions } from 'vuex';

import APIService from '@s/api.service';

import ODNIconOffline from '@c/odn-icon-offline.vue';

BarcodeReader.license = process.env.VUE_APP_DBR_LICENSE || '';
BarcodeReader.engineResourcePath =
  'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode/dist/';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    ODNIconOffline,
  },
  computed: {
    ...mapState('offline', ['networkStatus']),
    isOffline() {
      return this.networkStatus && !this.networkStatus.connected;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION || '0';
    },
    platforms() {
      return getPlatforms();
    },
    isCapacitorAndroid() {
      return (
        this.platforms.includes('capacitor') &&
        this.platforms.includes('android')
      );
    },
    isCapacitorIOS() {
      return (
        this.platforms.includes('capacitor') && this.platforms.includes('ios')
      );
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newLocale) {
        this.updateHtmlLang(newLocale);
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('auth/loadProfile');
    await this.$store.dispatch('prefs/loadFirstLogin');
    await this.$store.dispatch('prefs/loadLocale');
    await this.$store.dispatch('prefs/loadSaveImagesToGallery');
    await this.$store.dispatch('stores/init');
    await this.$store.dispatch('surveys/init');
    await this.$store.dispatch('tickets/init');
    await this.$store.dispatch('offline/loadState');
    if (this.$store.state.prefs.locale) {
      this.$i18n.locale = this.$store.state.prefs.locale;
    }
    await this.fetchSettings();
  },
  async mounted() {
    Network.getStatus().then((status) => {
      this.networkStatusChange(status);
    });

    Network.addListener('networkStatusChange', (status) => {
      this.networkStatusChange(status);
    });
  },
  methods: {
    ...mapActions('offline', ['networkStatusChange']),
    ...mapActions('settings', ['updateKeys']),
    updateHtmlLang(lang) {
      document.documentElement.setAttribute('lang', lang);
    },
    async fetchSettings() {
      try {
        const settings = (await APIService.get('/settings?filter=light')).data;
        this.updateKeys(settings);

        if (this.isCapacitorAndroid || this.isCapacitorIOS) {
          if (
            this.$store.state.settings.keys.APP_STORE_VERSION &&
            this.$store.state.settings.keys.APP_STORE_VERSION !==
              this.appVersion
          ) {
            this.showUpdateAlert();
          }
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.version.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      }
    },
    async showUpdateAlert() {
      const alert = await alertController.create({
        header: this.$t('messages.update.available'),
        message: this.$t('messages.update.newVersion'),
        buttons: [this.$t('buttons.ok')],
      });
      await alert.present();
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: url('/public/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/public/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/public/assets/fonts/Poppins-SemiBold.ttf');
}

:root {
  --ion-font-family: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif;
  --color-staging: #b0f2b6;
}

.ios ion-card-title {
  font-size: 20px;
}

@for $i from 0 through 8 {
  .odn-pad-#{$i * 2} {
    padding: #{$i * 2px};
  }
  .odn-pal-#{$i * 2} {
    padding-left: #{$i * 2px};
  }
  .odn-pat-#{$i * 2} {
    padding-top: #{$i * 2px};
  }
  .odn-par-#{$i * 2} {
    padding-right: #{$i * 2px};
  }
  .odn-pab-#{$i * 2} {
    padding-bottom: #{$i * 2px};
  }
  .odn-pax-#{$i * 2} {
    padding-left: #{$i * 2px};
    padding-right: #{$i * 2px};
  }
  .odn-pay-#{$i * 2} {
    padding-top: #{$i * 2px};
    padding-bottom: #{$i * 2px};
  }

  .odn-mar-#{$i * 2} {
    margin: #{$i * 2px};
  }
  .odn-mal-#{$i * 2} {
    margin-left: #{$i * 2px};
  }
  .odn-mat-#{$i * 2} {
    margin-top: #{$i * 2px};
  }
  .odn-mar-#{$i * 2} {
    margin-right: #{$i * 2px};
  }
  .odn-mab-#{$i * 2} {
    margin-bottom: #{$i * 2px};
  }
  .odn-max-#{$i * 2} {
    margin-left: #{$i * 2px};
    margin-right: #{$i * 2px};
  }
  .odn-may-#{$i * 2} {
    margin-top: #{$i * 2px};
    margin-bottom: #{$i * 2px};
  }
}

.odn-code {
  font-family: Courier, monospace !important;
}

.odn-staging-bar {
  position: absolute;
  width: 100%;
  height: 6px;
  left: 0;
  top: 0;
  background-color: var(--color-staging);
  z-index: 10;
}

.offline-status {
  position: absolute;
  top: 140px;
  right: 0;
  width: 48px;
  height: 48px;
  background-color: var(--ion-color-danger);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;

  & > svg {
    width: 30px;
    height: 30px;
  }
}
</style>
