<template>
  <ion-page>
    <div v-if="isStaging" class="odn-staging-bar"></div>
    <ion-content fullscreen>
      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button @click="onLanguagesModal" color="light">
          <ion-icon :icon="icons.earthOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <form @submit.prevent="onLogin" @keyup.enter="onLogin" class="odn-login">
        <img :src="`${s3Url}/medias/images/logo.png`" class="odn-login-logo" />
        <ion-list>
          <ion-item>
            <ion-input
              v-model="userData.email"
              :label="$t('fields.email.label')"
              label-placement="floating"
              type="email"
              required
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              v-model="userData.password"
              :label="$t('fields.password.label')"
              label-placement="floating"
              type="password"
              required
            ></ion-input>
          </ion-item>
        </ion-list>
        <ion-row class="odn-login-buttons">
          <ion-col>
            <ion-button type="submit" expand="block" :disabled="processing">
              <ODNSpinner v-show="processing" slot="end" />
              <span v-show="!processing">{{ $t('buttons.login') }}</span>
            </ion-button>
            <ion-row>
              <ion-col size="12" size-sm>
                <ion-label
                  class="odn-login-link"
                  router-link="/auth/forgotten-password"
                >
                  {{ $t('links.forgottenPassword') }}
                </ion-label>
              </ion-col>
              <ion-col size="12" size-sm>
                <ion-label
                  class="odn-login-link"
                  router-link="/auth/first-login"
                >
                  {{ $t('links.firstLogin') }}
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  toastController,
  modalController,
} from '@ionic/vue';

import { earthOutline, lockClosedOutline } from 'ionicons/icons';

import ODNSpinner from '@c/odn-spinner.vue';

import ODNModalLanguages from '@m/odn-modal-languages.vue';

export default {
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonFab,
    IonFabButton,
    IonIcon,
    ODNSpinner,
  },
  data() {
    return {
      processing: false,
      userData: {
        email: '',
        password: '',
      },
      icons: {
        earthOutline,
        lockClosedOutline,
      },
    };
  },
  computed: {
    ...mapState('auth', ['loggedIn', 'authData']),
    ...mapState('prefs', ['firstLogin']),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
    isStaging() {
      return (
        process.env.VUE_APP_IS_STAGING &&
        process.env.VUE_APP_IS_STAGING == 'true'
      );
    },
  },
  methods: {
    ...mapActions('auth', {
      login: 'login',
    }),
    async onLanguagesModal() {
      const modal = await modalController.create({
        component: ODNModalLanguages,
      });
      return modal.present();
    },
    async onLogin() {
      this.processing = true;
      if (this.userData.email && this.userData.password) {
        await this.login(this.userData);
        this.processing = false;
        if (this.loggedIn) {
          this.userData = {
            email: '',
            password: '',
          };
          this.$router.push('/menu');
          /* return this.firstLogin
            ? this.$router.push('/welcome')
            : this.$router.push('/menu'); */ // Remove permanently?
        } else {
          const toast = await toastController.create({
            message: this.$t('messages.login.post.error'),
            color: 'danger',
            duration: 2000,
          });
          return toast.present();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.odn-login {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);

  &-logo {
    display: block;
    width: 100%;
    max-width: 128px;
    height: auto;
    margin: 0 auto 3rem auto;
  }

  &-buttons {
    margin-top: 1rem;
    text-align: center;
  }

  &-link {
    display: inline-block;
    margin-top: 0.8rem;
    color: var(--ion-color-medium);
    cursor: pointer;
  }
}
</style>
