<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.projects') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionInput="onSearch"
          :debounce="300"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list style="min-height: 100%">
        <ion-item
          v-for="project in projects"
          :key="project.id"
          button
          @click="onSelect(project)"
          :disabled="isSelected(project)"
          :detail="false"
        >
          <ion-avatar
            v-if="project.customer && project.customer.logo"
            slot="start"
          >
            <img :src="project.customer.logo" />
          </ion-avatar>
          <ion-avatar v-else slot="start">
            <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
          </ion-avatar>
          <ion-label>{{ project.name }}</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-item>
      </ion-list>

      <ODNNoContent v-if="projects.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonList,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonAvatar,
  IonRippleEffect,
  modalController,
  toastController,
} from '@ionic/vue';
import { close, search } from 'ionicons/icons';

import APIService from '@s/api.service';

import ODNNoContent from '@c/odn-no-content.vue';

export default {
  name: 'ModalProjectList',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonLabel,
    IonList,
    IonItem,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonAvatar,
    IonRippleEffect,
    ODNNoContent,
  },
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      projects: [],
      page: 1,
      limit: 20,
      searchText: '',
      loading: true,
      disableInfiniteScrolling: true,
      icons: {
        close,
        search,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get('/projects', {
          page: this.page,
          limit: this.limit,
          search: this.searchText,
          sort: 'project.name',
          direction: 'asc',
          ongoing: true,
        });

        if (clear) {
          this.projects = [];
        }

        this.projects = this.projects.concat(response.data.items);

        if (this.projects.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.projects.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    dismiss() {
      modalController.dismiss();
    },
    onSelect(project) {
      const { id, name } = project;
      modalController.dismiss({ id, name });
    },
    isSelected(project) {
      return this.selected.filter((r) => r.id === project.id).length > 0;
    },
  },
};
</script>
