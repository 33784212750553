import { Preferences } from '@capacitor/preferences';

const state = () => ({
  locale: null,
  firstLogin: true,
  saveImagesToGallery: false,
  tabs: true,
});

const mutations = {
  SET_LOCALE(state, payload) {
    state.locale = payload;
  },
  SET_FIRST_LOGIN(state) {
    state.firstLogin = false;
  },
  SET_SAVE_IMAGES_TO_GALLERY(state, payload) {
    state.saveImagesToGallery = payload === true || payload === 'true';
  },
  SET_TABS(state, payload) {
    state.tabs = payload;
  },
};

const actions = {
  async setLocale({ commit }, payload) {
    commit('SET_LOCALE', payload);

    await Preferences.set({
      key: 'prefs.locale',
      value: payload.toLowerCase(),
    });
  },
  async loadLocale({ commit }) {
    const locale = await Preferences.get({ key: 'prefs.locale' });

    if (locale.value) {
      commit('SET_LOCALE', locale.value);
    }
  },
  async setFirstLogin({ commit }) {
    commit('SET_FIRST_LOGIN');

    await Preferences.set({
      key: 'prefs.firstLogin',
      value: false,
    });
  },
  async loadFirstLogin({ commit }) {
    const firstLogin = await Preferences.get({ key: 'prefs.firstLogin' });

    if (firstLogin.value) {
      commit('SET_FIRST_LOGIN');
    }
  },
  async setSaveImagesToGallery({ commit }, payload) {
    commit('SET_SAVE_IMAGES_TO_GALLERY', payload);

    await Preferences.set({
      key: 'prefs.saveImagesToGallery',
      value: payload,
    });
  },
  async loadSaveImagesToGallery({ commit }) {
    const saveImagesToGallery = await Preferences.get({
      key: 'prefs.saveImagesToGallery',
    });

    if (saveImagesToGallery.value) {
      commit('SET_SAVE_IMAGES_TO_GALLERY', saveImagesToGallery.value);
    }
  },
  setTabs({ commit }, payload) {
    commit('SET_TABS', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
