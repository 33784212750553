<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('views.productScan.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div class="product-scan">
        <div class="product-scan-scanner">
          <!-- DBR -->
          <div ref="scannerRef" class="dce-component">
            <svg class="dce-bg-loading" viewBox="0 0 1792 1792">
              <path
                d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"
              ></path>
            </svg>
            <svg class="dce-bg-camera" viewBox="0 0 2048 1792">
              <path
                d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"
              ></path>
            </svg>
            <div class="dce-video-container"></div>
            <div class="dce-scanarea">
              <!-- <div class="dce-scanlight"></div> -->
            </div>
            <!-- <div class="div-select-container">
              <select class="dce-sel-camera"></select>
              <select class="dce-sel-resolution"></select>
            </div> -->
          </div>
        </div>
        <div class="product-scan-fields">
          <!-- Product -->
          <ODNFormField
            :error="
              isScanned && errors.productId ? $t('errors.required') : null
            "
          >
            <template #alt-label>
              {{ $t('fields.product.label') }}
            </template>
            <template #alt-content>
              <div class="odn-pay-4">
                {{ selectedProduct ? selectedProduct.name : $t('labels.none') }}
              </div>
              <ion-button color="light" expand="block" @click="onModalProducts">
                <ion-icon slot="start" :icon="icons.search"></ion-icon>
                {{ $t('buttons.selectProduct') }}
              </ion-button>
            </template>
          </ODNFormField>
          <!-- Location status -->
          <ODNFormFieldContainer
            :error="
              isScanned && errors.locationStatusId
                ? $t('errors.required')
                : null
            "
          >
            <ion-select
              v-if="!locationStatusesLoading"
              v-model="form.locationStatusId"
              :placeholder="$t('fields.locationStatus.placeholder')"
              :cancelText="$t('buttons.cancel')"
              :okText="$t('buttons.validate')"
              :label="$t('fields.locationStatus.label')"
              label-placement="stacked"
            >
              <ion-select-option
                v-for="locationStatus in filteredLocationStatuses"
                :key="locationStatus.id"
                :value="locationStatus.id"
              >
                {{ $t(`locationStatuses.${locationStatus.name}`) }}
              </ion-select-option>
            </ion-select>
          </ODNFormFieldContainer>
          <!-- Company -->
          <ODNFormField
            v-if="form.locationStatusId === deliveryLocationStatusId"
          >
            <template #alt-label>
              {{ $t('fields.company.label') }}
            </template>
            <template #alt-content>
              <div class="odn-pay-4">
                {{ selectedCompany ? selectedCompany.name : $t('labels.none') }}
              </div>
              <ion-button
                color="light"
                expand="block"
                @click="onModalCompanies"
              >
                <ion-icon slot="start" :icon="icons.search"></ion-icon>
                {{ $t('buttons.selectCompany') }}
              </ion-button>
              <ion-button
                v-if="selectedCompany"
                color="danger"
                fill="outline"
                expand="block"
                @click="onDeselectCompany"
              >
                <ion-icon
                  slot="start"
                  :icon="icons.closeCircleOutline"
                ></ion-icon>
                {{ $t('buttons.delete') }}
              </ion-button>
            </template>
          </ODNFormField>
          <!-- Provider -->
          <ODNFormField>
            <template #alt-label>
              {{ $t('fields.provider.label') }}
            </template>
            <template #alt-content>
              <div class="odn-pay-4">
                {{
                  selectedProvider ? selectedProvider.name : $t('labels.none')
                }}
              </div>
              <ion-button
                color="light"
                expand="block"
                @click="onModalProviders"
              >
                <ion-icon slot="start" :icon="icons.search"></ion-icon>
                {{ $t('buttons.selectProvider') }}
              </ion-button>
              <ion-button
                v-if="selectedProvider"
                color="danger"
                fill="outline"
                expand="block"
                @click="onDeselectProvider"
              >
                <ion-icon
                  slot="start"
                  :icon="icons.closeCircleOutline"
                ></ion-icon>
                {{ $t('buttons.delete') }}
              </ion-button>
            </template>
          </ODNFormField>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  modalController,
  toastController,
} from '@ionic/vue';
import { barcodeOutline, closeCircleOutline } from 'ionicons/icons';

import {
  BarcodeScanner,
  EnumBarcodeFormat,
} from 'dynamsoft-javascript-barcode';

import { mapState } from 'vuex';

import ODNFormField from '@c/odn-form-field.vue';
import ODNFormFieldContainer from '@c/odn-form-field-container.vue';

import ODNModalSelect from '@m/odn-modal-select.vue';
import ODNModalGroupedSelect from '@m/odn-modal-grouped-select.vue';

import APIService from '@s/api.service';

export default {
  name: 'ProductScan',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon,
    ODNFormField,
    ODNFormFieldContainer,
  },
  data() {
    return {
      scanner: null,
      rawProducts: [],
      productsLoading: false,
      selectedProduct: null,
      locationStatuses: [],
      locationStatusesLoading: false,
      companies: [],
      companiesLoading: false,
      selectedCompany: null,
      providers: [],
      providersLoading: false,
      selectedProvider: null,
      isScanned: false,
      // Form
      form: {
        productId: null,
        locationStatusId: null,
        imei: null,
        companyId: null,
        providerId: null,
      },
      // Icons
      icons: {
        barcodeOutline,
        closeCircleOutline,
      },
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    deliveryLocationStatusId() {
      return this.locationStatuses.find((ls) => ls.name === 'DELIVERY')?.id;
    },
    filteredLocationStatuses() {
      return this.locationStatuses.filter(
        (ls) =>
          ls.name !== 'DELIVERY' ||
          ['superadmin', 'admin', 'pm', 'customer', 'support'].includes(
            this.profile.role.name
          )
      );
    },
    errors() {
      return {
        locationStatusId: !this.form.locationStatusId,
        productId: !this.form.productId,
        companyId:
          this.form.locationStatusId === this.deliveryLocationStatusId &&
          !this.form.companyId,
      };
    },
    products() {
      const groupedProducts = [];

      this.rawProducts.forEach((category) => {
        if (category.products) {
          groupedProducts.push({
            name: category.name,
            items: category.products.map((product) => {
              return {
                id: product.id,
                name: product.name,
                rootProductCategoryId: category.id,
              };
            }),
          });
        }

        category.children.forEach((subcategory) => {
          if (subcategory.products) {
            groupedProducts.push({
              name: `${category.name} - ${subcategory.name}`,
              items: subcategory.products.map((product) => {
                return {
                  id: product.id,
                  name: product.name,
                  rootProductCategoryId: category.id,
                };
              }),
            });
          }
        });
      });

      return groupedProducts;
    },
  },
  created() {
    this.fetchProducts();
    this.fetchLocationStatuses();
    this.fetchCompanies();
    this.fetchProviders();
  },
  ionViewDidEnter() {
    this.initScanner();
  },
  async ionViewDidLeave() {
    if (this.pScanner) {
      (await this.pScanner).destroyContext();
      this.scanner = null;
    }
  },
  methods: {
    async initScanner() {
      try {
        // Load Dynamsoft Barcode Reader WASM
        await BarcodeScanner.loadWasm();

        // Create a new instance of BarcodeScanner
        this.scanner = await (this.pScanner = BarcodeScanner.createInstance());

        // Set runtime settings (QR Code)
        let settings = await this.scanner.getRuntimeSettings();
        settings.barcodeFormatIds = EnumBarcodeFormat.BF_CODE_128;
        await this.scanner.updateRuntimeSettings(settings);

        // Set UI Element
        await this.scanner.setUIElement(this.$refs.scannerRef);

        // Set video fit mode
        this.scanner.setVideoFit('cover');

        // Set onUniqueRead event
        this.scanner.onUniqueRead = async (txt, result) => {
          this.onScanned(txt, result);
        };

        // Open the scanner
        await this.scanner.open();
      } catch (error) {
        const toast = await toastController.create({
          message: this.$t('messages.scan.init.error', { error }),
          color: 'danger',
          duration: 3000,
        });
        return toast.present();
      }
    },
    async onModalProducts() {
      // Pause the scanner
      this.scanner.pauseScan();

      const modal = await modalController.create({
        component: ODNModalGroupedSelect,
        componentProps: {
          title: this.$t('modals.products'),
          groupedItems: this.products,
          selected: this.form.productId,
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        // Assign selected product
        this.selectedProduct = result;
        this.form.productId = result.id;
      }

      // Resume the scanner
      this.scanner.resumeScan();
    },
    async onModalCompanies() {
      // Pause the scanner
      this.scanner.pauseScan();

      const modal = await modalController.create({
        component: ODNModalSelect,
        componentProps: {
          items: this.companies,
          selected: this.form.companyId,
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.selectedCompany = result;
        this.form.companyId = result.id;
      }

      // Resume the scanner
      this.scanner.resumeScan();
    },
    async onModalProviders() {
      // Pause the scanner
      this.scanner.pauseScan();

      const modal = await modalController.create({
        component: ODNModalSelect,
        componentProps: {
          items: this.providers,
          selected: this.form.providerId,
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.selectedProvider = result;
        this.form.providerId = result.id;
      }

      // Resume the scanner
      this.scanner.resumeScan();
    },
    onDeselectCompany() {
      this.selectedCompany = null;
      this.form.companyId = null;
    },
    onDeselectProvider() {
      this.selectedProvider = null;
      this.form.providerId = null;
    },
    async fetchProducts() {
      this.productsLoading = true;
      try {
        this.rawProducts = (
          await APIService.get('/products?filter=light')
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.products.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.productsLoading = false;
      }
    },
    async fetchLocationStatuses() {
      this.locationStatusesLoading = true;
      try {
        this.locationStatuses = (
          await APIService.get('/location-statuses')
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.locationStatuses.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.locationStatusesLoading = false;
      }
    },
    async fetchCompanies() {
      this.companiesLoading = true;
      try {
        this.companies = (
          await APIService.get('/companies?filter=light', {
            enabled: true,
          })
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.companies.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.companiesLoading = false;
      }
    },
    async fetchProviders() {
      this.providersLoading = true;
      try {
        this.providers = (await APIService.get('/providers?filter=light')).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.providers.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.providersLoading = false;
      }
    },
    async onScanned(txt) {
      // At least one scan has been done
      this.isScanned = true;

      // Check if form has errors
      const hasErrors = Object.values(this.errors).some((e) => e);

      // If form has errors, show a toast (product and status are required)
      if (hasErrors) {
        if (this.errors.companyId) {
          const toast = await toastController.create({
            message: this.$t('messages.scan.company.error'),
            color: 'danger',
            duration: 3000,
          });
          return toast.present();
        } else {
          const toast = await toastController.create({
            message: this.$t('messages.scan.form.error'),
            color: 'danger',
            duration: 3000,
          });
          return toast.present();
        }
      }

      // Check if the scan result is a 15/16 characters long
      if (txt.length === 15 || txt.length === 16) {
        // Assign imei to the form
        this.form.imei = txt;

        // Send a update request to the server
        this.sendToServer();
      } else {
        const toast = await toastController.create({
          message: this.$t('messages.scan.imei.error'),
          color: 'danger',
          duration: 4000,
        });
        return toast.present();
      }
    },
    async sendToServer() {
      try {
        if (this.form.locationStatusId !== this.deliveryLocationStatusId) {
          this.form.companyId = null;
        }

        const { data } = await APIService.post('/units/scan', this.form);

        const toast = await toastController.create({
          message: this.$t('messages.scan.imei.success', {
            imei: data.imei,
            product: data.product?.name,
          }),
          color: 'success',
          duration: 4000,
        });
        return toast.present();
      } catch (error) {
        const toast = await toastController.create({
          message: this.$t('messages.scan.imei.unknown', {
            imei: this.form.imei,
          }),
          color: 'danger',
          duration: 4000,
        });
        return toast.present();
      }
    },
  },
};
</script>

<style scoped>
.product-scan {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 2fr;
  height: 100%;
}

.product-scan-scanner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 16px;
}

.product-scan-scanner-type {
  margin-bottom: 16px;
}

.product-scan-scanner-box {
  flex: 1;
}

.product-scan-fields {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
}

.dce-component {
  position: relative;
  width: 100%;
  height: 20vh;
  min-height: 120px;
  border: 2px dotted var(--ion-color-medium);
  border-radius: 8px;
  resize: both;
}

.dce-bg-loading {
  display: none;
  animation: 1s linear infinite dce-rotate;
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}

.dce-bg-camera {
  display: none;
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}

.dce-video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.dce-scanarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/* .dce-scanlight {
  display: none;
  width: 100%;
  height: 1%;
  position: absolute;
  animation: 3s infinite dce-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px red;
  background: #fff;
} */

/* .div-select-container {
  position: absolute;
  left: 0;
  top: 0;
} */

/* .dce-sel-camera {
  display: block;
}

.dce-sel-resolution {
  display: block;
  margin-top: 5px;
} */

@keyframes dce-rotate {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

/* @keyframes dce-scanlight {
  from {
    top: 0;
  }

  to {
    top: 97%;
  }
}

@keyframes dbrScanner-scanlight {
  from {
    top: 0;
  }

  to {
    top: 97%;
  }
} */
</style>
