<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" default-href="/tabs/help"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t('views.procedures.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onFilterModal">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionInput="onSearch"
          :debounce="300"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="procedure in procedures"
        :key="procedure.id"
        button
        :router-link="`/tabs/help/procedures/${procedure.id}`"
      >
        <ion-card-header>
          <ion-card-title>{{ procedure.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div v-if="procedure.project">
            {{ procedure.project.name }} -
            {{ procedure.project.customer.name }}
          </div>
          <div v-else>
            {{ $t('labels.withoutProject') }}
          </div>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="procedures.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  modalController,
  toastController,
} from '@ionic/vue';
import { filterOutline } from 'ionicons/icons';

import ODNModalProceduresFilters from '@m/odn-modal-procedures-filters.vue';
import ODNNoContent from '@c/odn-no-content.vue';

import APIService from '@s/api.service';

export default {
  name: 'Procedures',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    ODNNoContent,
  },
  data() {
    return {
      procedures: [],
      filters: {
        projects: [],
        sort: 'procedure.created',
        direction: 'desc',
      },
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      filterOutline,
      loading: true,
    };
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.procedures = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get('/procedures', {
          page: this.page,
          limit: this.limit,
          search: this.searchText,
          sort: this.filters.sort,
          direction: this.filters.direction,
          projects: this.filters.projects.length
            ? this.filters.projects.map((r) => r.id).join(',')
            : null,
        });

        if (clear) {
          this.procedures = [];
        }

        this.procedures = this.procedures.concat(response.data.items);

        if (this.procedures.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.procedures.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    async onFilterModal() {
      const modal = await modalController.create({
        component: ODNModalProceduresFilters,
        componentProps: {
          filters: this.filters,
        },
      });
      await modal.present();

      // wait for a response when closing the modal
      const newFilters = (await modal.onDidDismiss()).data;

      if (newFilters) {
        this.filters = newFilters;
        this.fetchData(true);
      }
    },
  },
};
</script>
