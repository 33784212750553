<template>
  <div
    class="odn-form-field-container"
    :class="{ 'odn-form-field-container-error': error }"
  >
    <ion-item lines="none">
      <slot />
    </ion-item>
    <div v-if="error" class="odn-form-field-container-error-message">
      <ion-icon :icon="icons.warning"></ion-icon>
      {{ error }}
    </div>
  </div>
</template>

<script>
import { IonItem, IonIcon } from '@ionic/vue';
import { warning } from 'ionicons/icons';

export default {
  components: {
    IonItem,
    IonIcon,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: { warning },
    };
  },
};
</script>

<style lang="scss">
.odn-form-field-container {
  padding: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &-error {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
  }

  &-error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 16px 16px;
    font-size: 0.8em;
    color: var(--ion-color-danger);

    > ion-icon {
      margin-right: 0.5rem;
    }
  }

  > .ion-focused {
    border-radius: 6px;
  }

  > ion-item {
    --background: transparent;
  }

  img,
  video {
    display: block;
    width: calc(100% - 6px);
    margin: 0 auto 8px auto;
    border-radius: 4px;
  }

  ion-button {
    margin-top: 8px;
  }
}
</style>
