import en from './locales/en.js';
import fr from './locales/fr.js';
import it from './locales/it.js';
/* import de from './locales/de.js';
import es from './locales/es.js';
import ja from './locales/ja.js';
import ko from './locales/ko.js';
import nl from './locales/nl.js';
import pl from './locales/pl.js';
import pt from './locales/pt.js';
import ru from './locales/ru.js'; */

export default {
  en,
  fr,
  it,
  /* de,
  es,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru, */
};
