const state = () => ({
  keys: {},
});

const mutations = {
  UPDATE_KEYS(state, payload) {
    const newKeys = {};
    payload.forEach((item) => {
      newKeys[item.key] = item.value;
    });
    state.keys = newKeys;
  },
};

const actions = {
  updateKeys({ commit }, payload) {
    if (!Array.isArray(payload)) return;

    commit('UPDATE_KEYS', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
