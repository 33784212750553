<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.comment') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="onSave">
        <ODNForm>
          <ODNFormFieldContainer>
            <ion-textarea
              v-model="form.comment"
              :auto-grow="true"
              :autofocus="true"
              :label="$t('fields.comment.label')"
              label-placement="stacked"
            ></ion-textarea>
          </ODNFormFieldContainer>
          <div class="odn-mat-16">
            <ion-button
              type="submit"
              color="primary"
              :disabled="saving"
              expand="block"
            >
              {{ $t('buttons.validate') }}
              <ODNSpinner v-if="saving" slot="end" />
              <ion-icon v-else slot="end" :icon="icons.checkmark"></ion-icon>
            </ion-button>
          </div>
        </ODNForm>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonTextarea,
  modalController,
  toastController,
} from '@ionic/vue';
import { close, checkmark } from 'ionicons/icons';

import ODNForm from '@c/odn-form.vue';
import ODNFormFieldContainer from '@c/odn-form-field-container.vue';
import ODNSpinner from '@c/odn-spinner.vue';

import APIService from '@s/api.service';

export default {
  name: 'ModalStoreComment',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTextarea,
    ODNForm,
    ODNFormFieldContainer,
    ODNSpinner,
  },
  props: {
    storeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        comment: null,
      },
      loading: false,
      saving: false,
      icons: {
        close,
        checkmark,
      },
    };
  },
  created() {
    this.fetchStore();
  },
  methods: {
    async fetchStore() {
      this.loading = true;
      try {
        const store = (await APIService.get(`/stores/${this.storeId}`)).data;
        this.form.comment = store.comment;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.store.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onSave() {
      this.saving = true;

      try {
        await APIService.put(`/stores/${this.storeId}/comment`, this.form);
        const toast = await toastController.create({
          message: this.$t('messages.store.put.success'),
          color: 'success',
          duration: 2000,
        });
        toast.present();
        modalController.dismiss(true);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.store.put.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      } finally {
        this.saving = false;
      }
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>
