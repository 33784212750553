import { createRouter, createWebHistory } from '@ionic/vue-router';

import Menu from '@v/Menu.vue';
import Login from '@v/auth/Login.vue';
import Tabs from '@v/Tabs.vue';
import Stores from '@v/stores/Stores.vue';
import StoreDetails from '@v/stores/StoreDetails.vue';
import Projects from '@v/projects/Projects.vue';
import Appointments from '@v/appointments/Appointments.vue';
import Help from '@v/Help.vue';
import Procedures from '@v/procedures/Procedures.vue';
import EmergencyNumbers from '@v/emergency-numbers/EmergencyNumbers.vue';
import Profile from '@v/profile/Profile.vue';
import Offline from '@v/Offline.vue';
import OfflineStores from '@v/offline/OfflineStores.vue';
import ProductScan from '@v/products/ProductScan.vue';

import store from '../store';

const routes = [
  {
    path: '/',
    redirect: '/menu',
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/forgotten-password',
    name: 'ForgottenPassword',
    component: () => import('@v/auth/ForgottenPassword.vue'),
  },
  {
    path: '/auth/first-login',
    name: 'FirstLogin',
    component: () => import('@v/auth/FirstLogin.vue'),
  },
  {
    path: '/auth/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@v/auth/ResetPassword.vue'),
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    meta: { requiredAuth: true },
  },
  {
    path: '/tabs',
    name: 'Tabs',
    component: Tabs,
    meta: { requiredAuth: true },
    children: [
      {
        path: '',
        name: 'TabsRedirect',
        redirect: '/tabs/stores',
      },
      {
        path: 'stores',
        name: 'Stores',
        component: Stores,
      },
      {
        path: 'stores/:id',
        name: 'StoreDetails',
        component: StoreDetails,
      },
      {
        path: 'stores/:id/surveys',
        name: 'StoreSurveys',
        component: () => import('@v/stores/StoreSurveys'),
      },
      {
        path: 'stores/:id/surveys/:surveyId',
        name: 'StoreSurvey',
        component: () => import('@v/stores/StoreSurvey'),
      },
      {
        path: 'stores/:id/surveys/:surveyId/responses',
        name: 'StoreSurveyResponses',
        component: () => import('@v/stores/StoreSurveyResponses'),
      },
      {
        path: 'stores/:id/surveys/:surveyId/responses/:responseId',
        name: 'StoreSurveyResponse',
        component: () => import('@v/stores/StoreSurveyResponse'),
      },
      {
        path: 'stores/:id/surveys/:surveyId/responses/:responseId/edit',
        name: 'StoreSurveyResponseEdit',
        component: () => import('@v/stores/StoreSurveyResponseEdit'),
      },
      {
        path: 'stores/:id/tickets',
        name: 'StoreTickets',
        component: () => import('@v/stores/StoreTickets'),
      },
      {
        path: 'stores/:id/tickets/:ticketId',
        name: 'StoreTicketDetails',
        component: () => import('@v/stores/StoreTicketDetails'),
      },
      {
        path: 'stores/:id/tickets/:ticketId/edit',
        name: 'StoreTicketEdit',
        component: () => import('@v/stores/StoreTicketEdit'),
      },
      {
        path: 'stores/:id/tickets/add',
        name: 'StoreTicketAdd',
        component: () => import('@v/stores/StoreTicketAdd'),
      },
      {
        path: 'stores/:id/appointments',
        name: 'StoreAppointments',
        component: () => import('@v/stores/StoreAppointments'),
      },
      {
        path: 'stores/:id/appointments/:appointmentId',
        name: 'StoreAppointmentDetails',
        component: () => import('@v/stores/StoreAppointmentDetails'),
      },
      {
        path: 'stores/:id/files',
        name: 'StoreFiles',
        component: () => import('@v/stores/StoreFiles'),
      },
      {
        path: 'stores/:id/furnitures',
        name: 'StoreFurnitures',
        component: () => import('@v/stores/StoreFurnitures'),
      },
      {
        path: 'stores/:id/contacts',
        name: 'StoreContacts',
        component: () => import('@v/stores/StoreContacts'),
      },
      {
        path: 'stores/:id/contacts/:contactId',
        name: 'StoreContactDetails',
        component: () => import('@v/stores/StoreContactDetails'),
      },
      {
        path: 'stores/:id/contacts/add',
        name: 'StoreContactAdd',
        component: () => import('@v/stores/StoreContactAdd'),
      },
      {
        path: 'stores/:id/contacts/:contactId/edit',
        name: 'StoreContactEdit',
        component: () => import('@v/stores/StoreContactEdit'),
      },
      {
        path: 'stores/:id/wlans',
        name: 'StoreWlans',
        component: () => import('@v/stores/StoreWlans'),
      },
      {
        path: 'stores/:id/wlans/:wlanId',
        name: 'StoreWlanDetails',
        component: () => import('@v/stores/StoreWlanDetails'),
      },
      {
        path: 'stores/:id/wlans/add',
        name: 'StoreWlanAdd',
        component: () => import('@v/stores/StoreWlanAdd'),
      },
      {
        path: 'stores/:id/wlans/:wlanId/edit',
        name: 'StoreWlanEdit',
        component: () => import('@v/stores/StoreWlanEdit'),
      },
      {
        path: 'projects',
        name: 'Projects',
        component: Projects,
      },
      {
        path: 'projects/:id',
        name: 'ProjectDetails',
        component: () => import('@v/projects/ProjectDetails'),
      },
      {
        path: 'projects/:id/surveys',
        name: 'ProjectSurveys',
        component: () => import('@v/projects/ProjectSurveys'),
      },
      {
        path: 'projects/:id/surveys/:surveyId/stores',
        name: 'ProjectSurveyStores',
        component: () => import('@v/projects/ProjectSurveyStores'),
      },
      {
        path: 'projects/:id/surveys/:surveyId',
        name: 'ProjectSurvey',
        component: () => import('@v/projects/ProjectSurvey'),
      },
      {
        path: 'projects/:id/tickets',
        name: 'ProjectTickets',
        component: () => import('@v/projects/ProjectTickets'),
      },
      {
        path: 'projects/:id/appointments',
        name: 'ProjectAppointments',
        component: () => import('@v/projects/ProjectAppointments'),
      },
      {
        path: 'projects/:id/appointments/:appointmentId',
        name: 'ProjectAppointmentDetails',
        component: () => import('@v/projects/ProjectAppointmentDetails'),
      },
      {
        path: 'projects/:id/procedures',
        name: 'ProjectProcedures',
        component: () => import('@v/projects/ProjectProcedures'),
      },
      {
        path: 'projects/:id/stores',
        name: 'ProjectStores',
        component: () => import('@v/projects/ProjectStores'),
      },
      {
        path: 'appointments',
        name: 'Appointments',
        component: Appointments,
        children: [
          {
            path: 'list',
            name: 'AppointmentList',
            component: () => import('@v/appointments/AppointmentList'),
          },
          {
            path: 'calendar',
            name: 'AppointmentCalendar',
            component: () => import('@v/appointments/AppointmentCalendar'),
          },
          {
            path: 'add/:storeId?',
            name: 'AppointmentAdd',
            component: () => import('@v/appointments/AppointmentAdd'),
          },
          {
            path: ':id/edit',
            name: 'AppointmentEdit',
            component: () => import('@v/appointments/AppointmentEdit'),
          },
        ],
      },
      {
        path: 'appointments/:id',
        name: 'AppointmentDetails',
        component: () => import('@v/appointments/AppointmentDetails'),
      },
      {
        path: 'help',
        name: 'Help',
        component: Help,
      },
      {
        path: 'help/procedures',
        name: 'Procedures',
        component: Procedures,
      },
      {
        path: 'help/procedures/:id',
        name: 'ProcedureDetails',
        component: () => import('@v/procedures/ProcedureDetails'),
      },
      {
        path: 'help/procedures/:id/files',
        name: 'ProcedureFiles',
        component: () => import('@v/procedures/ProcedureFiles'),
      },
      {
        path: 'help/emergency-numbers',
        name: 'EmergencyNumbers',
        component: EmergencyNumbers,
      },
      {
        path: 'help/emergency-numbers/:id',
        name: 'EmergencyNumberDetails',
        component: () => import('@v/emergency-numbers/EmergencyNumberDetails'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'profile/edit',
        name: 'ProfileEdit',
        component: () => import('@v/profile/ProfileEdit'),
      },
      {
        path: 'profile/notifications',
        name: 'ProfileNotifications',
        component: () => import('@v/profile/ProfileNotifications'),
      },
      {
        path: 'profile/responses',
        name: 'ProfileResponses',
        component: () => import('@v/profile/ProfileResponses'),
      },
      {
        path: 'profile/responses/:responseId',
        name: 'ProfileResponse',
        component: () => import('@v/profile/ProfileResponse'),
      },
      {
        path: 'profile/tickets',
        name: 'ProfileTickets',
        component: () => import('@v/profile/ProfileTickets'),
      },
      {
        path: 'profile/offline',
        name: 'ProfileOffline',
        component: () => import('@v/profile/ProfileOffline'),
      },
      {
        path: 'profile/offline/saved-items',
        name: 'ProfileOfflineSavedItems',
        component: () => import('@v/profile/ProfileOfflineSavedItems'),
      },
      {
        path: 'profile/device-permissions',
        name: 'ProfileDevicePermissions',
        component: () => import('@v/profile/ProfileDevicePermissions'),
      },
      {
        path: 'profile/settings',
        name: 'ProfileSettings',
        component: () => import('@v/profile/ProfileSettings'),
      },
      {
        path: 'profile/informations',
        name: 'ProfileInformations',
        component: () => import('@v/profile/ProfileInformations'),
      },
      {
        path: 'products/scan',
        name: 'ProductScan',
        component: ProductScan,
      },
    ],
  },
  {
    path: '/offline',
    name: 'Offline',
    component: Offline,
    meta: { requiredAuth: true },
    children: [
      {
        path: '',
        name: 'OfflineRedirect',
        redirect: '/offline/stores',
      },
      {
        path: 'stores',
        name: 'OfflineStores',
        component: OfflineStores,
      },
      {
        path: 'stores/:id',
        name: 'OfflineStoreDetails',
        component: () => import('@v/offline/OfflineStoreDetails'),
      },
      {
        path: 'stores/:id/surveys',
        name: 'OfflineStoreSurveys',
        component: () => import('@v/offline/OfflineStoreSurveys'),
      },
      {
        path: 'stores/:id/surveys/:surveyId',
        name: 'OfflineStoreSurvey',
        component: () => import('@v/offline/OfflineStoreSurvey'),
      },
      {
        path: 'stores/:id/tickets',
        name: 'OfflineStoreTickets',
        component: () => import('@v/offline/OfflineStoreTickets'),
      },
      {
        path: 'stores/:id/tickets/add',
        name: 'OfflineStoreTicketAdd',
        component: () => import('@v/offline/OfflineStoreTicketAdd'),
      },
      {
        path: 'stores/:id/tickets/:ticketId',
        name: 'OfflineStoreTicketDetails',
        component: () => import('@v/offline/OfflineStoreTicketDetails'),
      },
      {
        path: 'stores/:id/contacts',
        name: 'OfflineStoreContacts',
        component: () => import('@v/offline/OfflineStoreContacts'),
      },
      {
        path: 'appointments',
        name: 'OfflineAppointments',
        component: () => import('@v/offline/OfflineAppointments'),
      },
      {
        path: 'appointments/:id',
        name: 'OfflineAppointmentDetails',
        component: () => import('@v/offline/OfflineAppointmentDetails'),
      },
      {
        path: 'procedures',
        name: 'OfflineProcedures',
        component: () => import('@v/offline/OfflineProcedures'),
      },
      {
        path: 'procedures/:id',
        name: 'OfflineProcedureDetails',
        component: () => import('@v/offline/OfflineProcedureDetails'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function guard(to, from, next, authData) {
  if (to.meta && to.meta.requiredAuth) {
    if (authData && authData.userId > 0) {
      return next();
    }
    return next({ path: '/auth/login' });
  } else {
    const authRoutes = [
      '/auth/login',
      '/auth/reset-password',
      '/auth/forgotten-password',
    ];
    if (authData && authData.userId > 0 && authRoutes.includes(to.path)) {
      return next({ path: '/menu' });
    }
    return next();
  }
}

router.beforeEach((to, from, next) => {
  let authData = store.getters['auth/getAuthData'];
  if (authData.userId == 0) {
    store.dispatch('auth/loadPreferencesTokens').then(
      () => {
        authData = store.getters['auth/getAuthData'];
        return guard(to, from, next, authData);
      },
      () => {
        store.dispatch('auth/logout');
        return guard(to, from, next, authData);
      }
    );
  } else {
    return guard(to, from, next, authData);
  }
});

export default router;
